import React from "react";
import ModuleRender from "@components/modules/moduleRender";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Stack from "react-bootstrap/Stack";

import ProjectCarouselAtext from "@components/modules/Banner/projectCarouselAtext";
import "./articleSidebar.scss";

const ArticleTextSidebar = ({ moduleData, sidebarData, page, isProject, ...rest }) => {
    if (moduleData.length > 0) {
        // Remove the spacing of modules as we have different spacing in sidebar
        const sidebarDataModified = sidebarData?.map(arr => ({...arr, moduleData: {...arr.moduleData, sectionSpacing: " ", removeContainer: true}}))
        const moduleDataModified = moduleData.map(arr => ({...arr, moduleData: {...arr.moduleData, sectionSpacing: " ", removeContainer: true}}))
        return(
            <div className="section py-40 py-md-64 py-lg-80 article-text-sidebar" id="article-text-sidebar">
                <Container fluid="lg">
                    <Row>
                        <Col xs={12} lg={{ span: 9, order: 2 }} xl={{ span: 9, order: 2 }}>
                            <Stack className="gap-40 gap-md-48">
                                {isProject &&
                                <ProjectCarouselAtext items={page?.Gallery} imagetransforms={page?.imagetransforms} pageId={page?.id} {...rest} />
                                }
                                <ModuleRender
                                    moduleData={moduleDataModified}
                                />
                            </Stack>
                        </Col>
                        {sidebarDataModified?.length > 0 &&
                            <Col xs={12} lg={{ span: 3, order: 1 }} xl={{ span: 2, order: 1 }} className="">

                                        <Stack
                                            //ref={ref}
                                            //initial="hidden"
                                            //animate={inView ? "visible" : "hidden"}
                                            //variants={fadeInFromTop}
                                            className="gap-24 gap-md-56 gap-lg-32 mt-40 mt-md-56 mt-lg-0 sidebar sidebar-float"
                                        >
                                            <ModuleRender
                                                moduleData={sidebarDataModified}
                                                divider={true}
                                            />
                                        </Stack>

                            </Col>
                        }
                    </Row>
                </Container>
            </div>
        )
    } else {
        return null
    }
}

export default ArticleTextSidebar
