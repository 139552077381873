import React, {useEffect} from "react";
import "@src/scss/App.scss";
import Seo from "@components/seo";
import Layout from "@components/layout";
import {Helmet } from "react-helmet"
import { graphql } from "gatsby";
import ModuleRender from "@components/modules/moduleRender";
import BannerRender from "@components/modules/Banner/BannerRender";
import { Routes } from '@components/routes';
import Stack from 'react-bootstrap/Stack';
import moment from 'moment';
import SocialShare from '@components/socialShare';
import ArticleText from '@components/articleTextSidebar';
import { StarberryIcons } from "@components/icons/index";
import OtherNews from '@components/modules/AllNews/otherNews';
import { getSrc } from "gatsby-plugin-image";
// import {
//     ImgBtnFragmentGql, //
//     NormalTextFragmentGql,
//     IntroTextFragmentGql,
//     ImageGridFragmentGql,
//     BookValuationFragmentGql,
//     TeamContactFragmentGql,
//     ManualTeamContactFragmentGql, //
//     SocialShareFragmentGql
// } from "@components/gql";
// import Loader from '@components/loader';
// import gql from "graphql-tag";
// import { useQuery } from '@apollo/client';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom"
import { useLocation } from "@reach/router"
import SubscribeNewsLetter from '@components/modules/Forms/SubscribeNewsLetter';
import NotFoundPage from "../pages/404"
const AfterTitle = ({ date, tag, ShowOnNewsLetterPage, issue }) => {
    return (
        <Stack direction="horizontal" className="gap-24 gap-md-48">
            {tag && !ShowOnNewsLetterPage && 
                <Stack direction="horizontal">
                    <div>
                        <StarberryIcons iconName="folderIcon" className="me-5 mt-n5" />
                    </div>
                    <div style={{ lineHeight: 1 }}>{tag}</div>
                </Stack>
            }
            {issue && ShowOnNewsLetterPage && 
                <Stack direction="horizontal"> 
                    <div style={{ lineHeight: 1 }}>Issue {issue}</div>
                </Stack>
            }
            {date &&
                <Stack direction="horizontal">
                    <div>
                        <StarberryIcons iconName="calendarIcon" className="me-5 mt-n5" />
                    </div>
                    <div style={{ lineHeight: 1 }}>
                        <div className="award-card__date">
                            {moment(date).format("D MMM YYYY")}
                        </div>
                    </div>
                </Stack>
            }
            <SocialShare
                shareLabel="Share"
                hideShareIcon={true}
            />
        </Stack>
    )
}

const NewsDetails = ({data}) => {
    // const { glstrapi: { allNew } } = data
    const allNew = [data?.glstrapi?.allNew] ?? [];

//     const GET_DETAIL_BLOG = gql`
//     query GetBlogDetail($Slug: String!) {
//         allNews(where:{Slug: $Slug}){
//             id
//             Slug
//             Pagename
//             Title
//             Date
//             tag
//             HeaderTemplate
//             imagetransforms
//             ShowOnNewsLetterPage
//             Issue
//             Attachment {
//                 url
//                 id
//             }
//             BannerImage {
//                 ... on ComponentModulesBannerImage {
//                     ...ImgBtnFragmentGql
//                 }
//             }
//             ArticleContent {
//                 ... on ComponentModulesNormalText {
//                     ...NormalTextFragmentGql
//                 }
//                 ... on ComponentModulesIntroText {
//                     ...IntroTextFragmentGql
//                 }
//                 ... on ComponentModulesImageGrid {
//                     ...ImageGridFragmentGql
//                 }
//                 ... on ComponentModulesBookValuation {
//                     ...BookValuationFragmentGql
//                 }
//             }
//             SidebarContent {
//                 ... on ComponentModulesTeamContact {
//                     ...TeamContactFragmentGql
//                 }
//                 ... on ComponentModulesManualTeamContact {
//                     ...ManualTeamContactFragmentGql
//                 }
//                 ... on ComponentModulesSocialShare {
//                     ...SocialShareFragmentGql
//                 }
//             }
//             Metadata {
//                 title
//                 description
//                 image {
//                     url
                    
//                 }
//             }
//         }
//     }
//     ${ImgBtnFragmentGql}
//     ${NormalTextFragmentGql}
//     ${IntroTextFragmentGql}
//     ${ImageGridFragmentGql}
//     ${BookValuationFragmentGql}
//     ${TeamContactFragmentGql}
//     ${ManualTeamContactFragmentGql}
//     ${SocialShareFragmentGql}
// `;
    // const { loading, error, data } = useQuery(GET_DETAIL_BLOG, {
    //     variables: { Slug: props.myslug }
    // });
    const location = useLocation();

    useEffect(() => {
        if(typeof window !== 'undefined'){
            window.scrollTo(0,0)
            // window.addEventListener('contextmenu', function (e) {
            //     e.preventDefault();
            //   });
        }
    }, [data])
    // if (loading) return (
    //     <Layout loading={loading}>
    //     <Loader loading={loading} error={error} hideText={true} fullScreen />
    // </Layout>
    // );
    if (allNew?.length === 0) return (
        <Layout className="blog-page">
      <Router>
        <Switch>
          <Route component={NotFoundPage} />
        </Switch>
      </Router>
    </Layout>
    )
    return (
        <Layout popularSearch={"Media_Layout"} className="blog-details-page">
            {allNew.map((Page, i) => {
                //console.log("Page",Page)
                const pageurl = typeof window !== 'undefined' ? window.location.pathname : Page?.Slug
                const breadcrumbs = [
                    { label: "Media", url: `${Routes.MediaPage}` },
                    { label: `${Page?.ShowOnNewsLetterPage ? "News": "Blog"} `, url: `${Routes.MediaPage}${Page?.ShowOnNewsLetterPage ? Routes.NewsPage: Routes.BlogPage}` },
                    { label: Page?.Pagename, url: `${pageurl}` }
                ]
                const metaDesc = `Read about ${Page.Title} here and subscribe to our newsletter to stay up-to-date about everything going on at Exclusive Links.`
                const metaImg = Page.BannerImage?.[0]?.Image?.url_sharp && `${process.env.GATSBY_SITE_URL}${getSrc(Page.BannerImage[0].Image.url_sharp)}`;
                return (
                    <>
                      <Helmet>
                        <script type="application/ld+json">{`{
                        
                                "@context": "https://schema.org",
                                "@type": "Article",
                                "mainEntityOfPage": {
                                "@type": "WebPage",
                                "@id": ""
                                },
                                "headline": "${Page?.Title}",
                                "image":{
                                "@type":"ImageObject",
                                "url":"${Page?.BannerImage[0]?.Image?.url}"
                                },
                                "datePublished": "${Page?.Date}",
                                "dateModified": "${Page?.Date}",
                                 "author": {
                                "@type": "Person",
                                "name": "${Page?.SidebarContent[0]?.team?.Name}",
                                 "url": "https://www.exclusive-links.com/about-exclusive-links/meet-the-team/${Page?.SidebarContent[0]?.team?.Slug}/",
                                 "sameAs": "https://www.linkedin.com/in/zarah-evans-12630761/",
                               "knowsAbout":["Real estate brokers in Dubai","Real estate agents in Dubai","Dubai real estate market","property management companies in dubai","Dubai off plan projects","properties for sale in Dubai","properties for sale in Qatar"],
                               "jobTitle": "${Page?.SidebarContent[0]?.team?.JobTitle}",
                                "image":{
                                "@type":"ImageObject",
                               "@id": "https://www.exclusive-links.com/about-exclusive-links/meet-the-team/zarah-evans/",
                               "url":"https://ggfx-exclusivelinks.s3.eu-west-2.amazonaws.com/i.prod/Zarah_New_Website_photo_864fa242af.jpg",
                               "caption": "Zarah Evans" 
                               },
                               "worksFor": 
                               { 
                               "@type": "OrganizationRole", 
                               "@id":"https://www.exclusive-links.com/about-exclusive-links/meet-the-team/zarah-evans/", 
                               "name": "Exclusive Links", 
                               "url": "https://www.exclusive-links.com/"
                               }
                               },
                                "publisher": {
                                "@type": "Organization",
                                "name": "Exclusive Links",
                                "logo": {
                                "@type": "ImageObject",
                                "url": "https://ggfx-pearlgates2.s3.eu-west-2.amazonaws.com/i.prod/pb_logo_2_043eb3ad46.png"
                                }
                                },
                                "description": "${metaDesc}"
                               
                                }`}
                        </script>  
        
         </Helmet>
                        <Seo
                            title={Page?.Title}
                            description={metaDesc}
                            image={metaImg}
                        />
                        {/* <Seo
                title={allNew.Metadata ? allNew?.Metadata.title : cleanP(allNew.Title)}
                description={allNew.Metadata ? allNew?.Metadata.description : null}
                image={allNew.Metadata && allNew?.Metadata?.image?.url_sharp?.childImageSharp ? allNew.Metadata.image.url_sharp.childImageSharp.resize : null}
            /> */}
            
                        <BannerRender
                            page={Page}
                            ggfxColl="all-news"
                            breadcrumbs={breadcrumbs}
                            afterTitle={<AfterTitle date={Page?.Date} tag={Page?.tag} ShowOnNewsLetterPage={Page?.ShowOnNewsLetterPage} issue={Page?.Issue} />}
                        />
                        {Page?.ArticleContent &&
                            <ArticleText
                                moduleData={Page?.ArticleContent}
                                sidebarData={Page?.SidebarContent}
                            />
                        }
                        {Page?.BannerImage &&
                            <ModuleRender
                                moduleData={Page?.BannerImage}
                            />
                        }
                        {Page?.Attachment &&
                        <SubscribeNewsLetter
                            sectionClass="section-contact-form"
                            title="Download this Issue"
                            attachment={Page?.Attachment?.url}
                        />
                        }
                         {!Page?.ShowOnNewsLetterPage &&
                          <OtherNews
                                moduleData={{
                                    Title: "Other blogs that may interest you...",
                                    moduleClass: "section-grey",
                                    sectionSpacing: "py-48 py-md-64 py-lg-120"
                                }}
                                currentNewsId={Page.id}
                            />
                         }
                       
                    </>
                )
            })}
        </Layout>
    );
}

export default NewsDetails

export const query = graphql`
    query ($id: ID!) {
        glstrapi {
            allNew(id: $id, publicationState: LIVE) {
                id
                Slug
                Pagename
                Title
                Date
                tag
                HeaderTemplate
                imagetransforms
                ShowOnNewsLetterPage
                Issue
                Attachment {
                    url
                    id
                }
                BannerImage {
                    ... on GLSTRAPI_ComponentModulesBannerImage {
                        ...ImgBtnFragment
                        Image {
                            url
                            url_sharp {
                            childImageSharp {
                                gatsbyImageData(width: 336, breakpoints: 336)
                            }
                            }
                        }
                    }
                }
                ArticleContent {
                    ... on GLSTRAPI_ComponentModulesNormalText {
                        ...NormalTextFragment
                    }
                    ... on GLSTRAPI_ComponentModulesIntroText {
                        ...IntroTextFragment
                    }
                    ... on GLSTRAPI_ComponentModulesImageGrid {
                        ...ImageGridFragment
                    }
                    ... on GLSTRAPI_ComponentModulesBookValuation {
                        ...BookValuationFragment
                    }
                }
                SidebarContent {
                    ... on GLSTRAPI_ComponentModulesTeamContact {
                        ...TeamContactFragment
                    }
                    ... on GLSTRAPI_ComponentModulesManualTeamContact {
                        ...ManualTeamContactFragment
                    }
                    ... on GLSTRAPI_ComponentModulesSocialShare {
                        ...SocialShareFragment
                    }
                }
            }
        }
    }
`