import React, {useState, useEffect} from "react"

import { Form, Row } from 'react-bootstrap'
import FieldRender from './renderFields'
import fields from './form/SubscribeNewsLetter.json'


// import '../assets/styles/_index.scss'
import { postFormData } from "./api/Api"

//import axios from "axios"
import * as qs from "query-string"

const SubscribeNewsLetterForm = (props) => {

  const [validated, setValidated] = useState(false);
  const [showerror, setShowerror] = useState(false);
  const [showthankyou, setThankyou] = useState(false);
  const [topic, setTopic] = useState("Subscribe to Newsletter");

  const [formvalues, setFormvalues] = useState("");

  const [token, setToken] = useState("");

  const myRef = React.createRef();

  const recaptchaRef = React.createRef();

  useEffect(() => {
    if (token !== '') {

      const processFromData = async () => {

        formvalues['g-recaptcha-response'] = token;

        let formData = new FormData();
        formvalues["name"] = formvalues.name
        formvalues["email_subject_user"] = fields[0].email_subject_user
        formvalues["email_subject_admin"] = fields[0].email_subject_admin

        formData.append('data', JSON.stringify(formvalues));
        const isBlocked = formvalues.email == "laylaalizada9@gmail.com" || formvalues.telephemailone == "6885678998";
        if(isBlocked){
          setThankyou(true);
          return;
        } else {
            postFormData(formData).then(async apiRes => {
              window.grecaptcha.reset()
              await window.fetch(`${process.env.GATSBY_CLOUD_URL}/api/formsubmission`, {
                method: `POST`,
                mode: "no-cors",
                headers: {
                  "Access-Control-Allow-Origin": "*",
                  "Content-Type": "application/x-www-form-urlencoded",
                },
                body: qs.stringify(formvalues),
              })

            })

              // tracking event
              window.dataLayer = window.dataLayer || [];
              window.dataLayer.push({
                'event': 'formSubmit',
                'formType': 'form-'+fields[0].event_tracking,
                'formId': 'form-'+fields[0].event_tracking,
                'formName': fields[0].formname,
                'formLabel': fields[0].formname
              });

              setShowerror(false);
              setThankyou(true);
              sessionStorage.removeItem('topic')
              myRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' })
            }
          }
      processFromData();
    }
  }, [token]);

  const handleonVerify = token => {
    console.log("captcha verified");
    setToken(token);
  };

  const handleSubmit = event => {

    const form = event.currentTarget;

    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      setShowerror(true);
      setValidated(true);
      setThankyou(false);
      myRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' })
    }
    else {

      event.preventDefault();

      const formsdata = (event.target);
      const json = {}

      Object.keys(formsdata).map(key => (
        json[formsdata[key].name] = (formsdata[key].checked) ? formsdata[key].value : formsdata[key].value
      ))

      json['email_temp_user'] = fields[0].email_temp_user;
      json['email_temp_admin'] = fields[0].email_temp_admin;
      json['formname'] = fields[0].formname;
      json['topic'] = sessionStorage.getItem('topic') === null ? "Subscribe to Newsletter" : sessionStorage.getItem('topic')
      json['g-recaptcha-response'] = token;

      setFormvalues(json);

      recaptchaRef.current.execute();  

      setValidated(false);

      // reset form
      const form = event.target
      form.reset();
 
      if(props.attachment){
        const link = document.createElement('a');
        link.setAttribute('target', '_blank');
        link.href = props.attachment;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
      

    }
  };
  var foundIndex = fields.findIndex(x => x.type == "submit");
  if(props.attachment){  
    fields[foundIndex].name = "Download";
  }else{
    fields[foundIndex].name = "Subscribe";
  }

  var termIndex = fields.findIndex(x => x.name == "terms");
  if(props.attachment){  
    fields[termIndex].text = "By clicking Download, you agree to our <a href='/terms-and-conditions' className='content-link'>Terms and Conditions</a>, <a href='/privacy-and-cookie-policy' className='content-link'>Privacy and Cookies Policy</a>.";
  }else{
    fields[termIndex].text = "By clicking on Subscribe, you agree to our <a href='/terms-and-conditions' className='content-link'>Terms and Conditions</a>, <a href='/privacy-and-cookie-policy' className='content-link'>Privacy and Cookies Policy</a>.";
  }

  return (
    <div className="form stbform" >
    <div ref={myRef} />

    {showerror && <div className="alert-error">
      <p>{fields[0].error_text}</p>
    </div>}

    {showthankyou && <div className="alert-success" dangerouslySetInnerHTML={{__html: fields[0].success_text}}></div>}

    
        <Form
            name={fields[0].formname}
            action="/thank-you/"
            className={props.formClass}
            method="post"
            noValidate
            validated={validated}
            onSubmit={handleSubmit}
            data-netlify="true"
            netlify-honeypot="bot-field"
            data-netlify-recaptcha="true"
        >
            <input type="hidden" name="form_name" value={fields[0].formname} />
            <input type="hidden" name="bot-field" />
            <Row className="mb-3" >
                <FieldRender setTopic={setTopic} recaptchaRef={recaptchaRef} handleonVerify={handleonVerify} fields={fields} />
            
            </Row>
            
        </Form>
    </div>
  );
}


const SubscribeNewsLetterComponent = (props) => (
    <SubscribeNewsLetterForm
        subject={props.subject}
        formClass={props.formClass}
        attachment={props.attachment}
    />
)

export default SubscribeNewsLetterComponent