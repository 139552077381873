import React from 'react';
import { graphql } from 'gatsby';
import { SwiperSlide } from 'swiper/react';
import Carousel from '@components/carousel';
import { StaticImage } from "gatsby-plugin-image";
import Lightbox from '@components/lightbox';
import ImageTransform from "@components/common/ggfx-client/module/components/image-transform";

import { containerVariants, contentItemStagger, contentItem } from '@components/utils/animations';

import "./carousel.scss";


const sliderConfig = {
    slidesPerView: 1,
    pagination: false,
    navigation: true,
}

const ProjectBannerCarouselAtext = ({ imagetransforms, pageId, items, breadcrumbs, breadcrumbsClass, onImage }) => {
    // GGFX
    let processedImages = JSON.stringify({});
    if (imagetransforms?.projectImageBanner_Transforms) {
        processedImages = imagetransforms?.projectImageBanner_Transforms;
    }
    return (
        
                <section
                    className="section section-primary project-banner-carousel"
                    // ref={ref}
                    // initial="hidden"
                    // animate={inView ? "visible" : "hidden"}
                    // variants={containerVariants}
                >
                    <div className="position-relative">
                        <Carousel  className="featured-carousel" {...sliderConfig}>
                            {items?.map((item, i) => {                               
                                return(
                                    <SwiperSlide key={i}>
                                        {item?.VideoUrl &&
                                            <Lightbox videoUrl={[`${item.VideoUrl}`]} lightboxType="youtube" className="project-image-banner__lightbox">
                                                <div className="overflow-hidden">
                                                    {item?.url ?
                                                        // <ImageTransform
                                                        //     imagesources={item.projectImageBanner.url}
                                                        //     renderer="srcSet"
                                                        //     imagename="projects.BannerImage.projectImageBanner"
                                                        //     attr={{ alt: "", className: "project-banner-carousel-item" }}
                                                        //     imagetransformresult={processedImages}
                                                        //     id={pageId}
                                                        // />
                                                        <img
                                                        src={item?.url}
                                                        // quality={100}
                                                        // formats={["auto", "webp"]}
                                                        alt={"Image - Exclusive Links"}
                                                        className="project-banner-carousel-item"
                                                    />
                                                    :
                                                        <StaticImage
                                                            src="../../../images/no-image.png"
                                                            width={1400}
                                                            height={540}
                                                            formats={["auto", "webp"]}
                                                            alt=""
                                                            className="project-banner-carousel-item"
                                                        />
                                                    }
                                                </div>
                                            </Lightbox>
                                        }
                                        {!item?.VideoUrl &&
                                            <>
                                                {item?.url ?
                                                    <ImageTransform
                                                        imagesources={item.url}
                                                        renderer="srcSet"
                                                        imagename="projects.BannerImage.projectImageBanner"
                                                        attr={{ alt: "Images-Gallery - Exclusive Links", className: "project-banner-carousel-item" }}
                                                        imagetransformresult={processedImages}
                                                        id={pageId}
                                                    />
                                                :
                                                    <StaticImage
                                                        src="../../../images/no-image.png"
                                                        width={1400}
                                                        height={540}
                                                        formats={["auto", "webp"]}
                                                        alt=""
                                                        className="project-banner-carousel-item"
                                                    />
                                                }
                                            </>
                                        }
                                    </SwiperSlide>
                                )
                            })}
                            {onImage && <>{onImage}</>}
                        </Carousel>
                        
                    </div>
                    {/* <div className="position-relative">
                        <div
                            className="container-fullsize overlay"
                            variants={contentItemStagger}
                        >
                            <StaticImage
                                src="../../../images/pattern.jpg"
                                quality={100}
                                formats={["auto", "webp"]}
                                alt="Background Image - Exclusive Links"
                                className="pattern-img"
                            />
                        </div>
                        <Container fluid="lg">
                            <Row>
                                <Col xs={12}>
                                    <Stack className="gap-16 gap-md-24 gap-lg-16 image-banner__content">
                                        <div variants={contentItem}>
                                            <Breadcrumbs
                                                breadcrumbs={breadcrumbs}
                                                breadcrumbsClass={breadcrumbsClass}
                                            />
                                        </div>
                                        {items[0]?.ItemTitle &&
                                            <div variants={contentItem}>
                                                <ModuleTitle
                                                    title={items[0].ItemTitle}
                                                    className="h6 text-white image-banner__title"
                                                    as="h1"
                                                />
                                            </div>
                                        }
                                        {items[0]?.ItemText &&
                                            <div variants={contentItem}>
                                                <ModuleText
                                                    text={items[0].ItemText}
                                                    className="image-banner__text d-block"
                                                />
                                            </div>
                                        }
                                        <Stack direction="vertical" className="gap-16 gap-md-24 flex-md-row mt-8 mt-lg-24 image-banner__btns" variants={contentItem}>
                                            <ButtonLink func="PropertySearchModal" variant="secondary" label="Find a Property" icon="searchIcon" />
                                            <ButtonLink manualLink={`${Routes.Sell}${Routes.RequestValuation}`} variant="secondary" label="Book a Valuation" icon="homeIcon" />
                                        </Stack>
                                    </Stack>
                                </Col>
                            </Row>
                        </Container>
                    </div> */}
                </section>
            
    )
}

export default ProjectBannerCarouselAtext

export const projectImageBannerCarouselFragment = graphql`
    fragment ProjectImageBannerCarouselFragment on GLSTRAPI_ComponentModulesBannerCarousel {
        id
        Items {
            ItemText
            ItemTitle
            VideoUrl
            showGoogleRating
            hideBreadcrumbs
            manualLink
            btnClass
            label
            icon
            page {
                Slug
                id
                Parent {
                    Slug
                }
                Parent2 {
                    Slug
                }
                Parent3 {
                    Slug
                }
            }
            projectImageBanner: Image {
                url
            }
        }
    }
`