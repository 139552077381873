import React,{useEffect} from "react";
import ModuleSection from '@components/modules/moduleWrapper';
import ModuleTitle from '@components/modules/Title';
import ModuleText from '@components/modules/Text';
import SubscribeNewsLetterForm from '@components/forms/SubscribeNewsLetter';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import TeamContact from '@components/modules/TeamContact';
import { useLocation } from "@reach/router"

import { fadeIn } from '@components/utils/animations';



const defaultText = '<p>We are looking for professional individuals that can share their experiences and knowledge with our audience and we cover a wide scope of topics. If you have any feedback or suggestions for our future episodes all opinions are welcomed.</p>'

const SubscribeNewsLetterModule = ({ sectionClass, title, text, attachment }) => {
    const location = useLocation();
    useEffect(()=>{ 
       if(location.hash === "#subscribeNewsletterForm"){
        setTimeout(()=>{ 
            document.getElementById("subscribeBtn").click();
        },1500)
       
       }
    },[location.hash])
    return(
        <ModuleSection sectionClass={sectionClass + ' target-element'} id="subscribeNewsletterForm">
            <Row>
                <Col xs={12} lg={8} variants={fadeIn} className="newsletter-form">
                    {title &&
                        <ModuleTitle
                            as="h3"
                            title={title}
                            className="module__title mb-16 mb-md-24"
                        />
                    }
                    {/* <ModuleText
                        text={text ? text : defaultText}
                        className="module__text mb-32 mb-md-56"
                    /> */}
                    <SubscribeNewsLetterForm attachment={attachment} />
                </Col>
            </Row>
        </ModuleSection>
    )
}

export default SubscribeNewsLetterModule;